<!-- ========================== intro (start) ========================== -->

<main class="l-deashboard summary" [formGroup]="summaryForm">
		<div class="h100 l-flex-w t-boxMargin--spacebyPadding">
			<div class="w50 summary-spreadHeight summary-recommendations" *ngIf="es.ExamType !== 'Fetal Echo' && es.ExamType !== 'Pediatric Echo' && es.ExamType !='CVS' && es.ExamType !='Amniocentesis' && es.ExamType !='Follicles'">
                    <div class="m-box" style="min-height: 5em;" *ngIf="es.ExamType !='CVS' && es.ExamType !='Amniocentesis' && es.ExamType !='Follicles'">

                            <h2>
                                <!-- Indicative Way of Examination -->
								{{'summary.indicative_way_of_examination.title' | translate | titlecase }}
                            </h2>
        
                            <div class="l-flex-c l-flex-w indicative-fieldset h100">
                                
                                <label class="t-label-top">
        
                                    <input type="checkbox" formControlName="Abdominal" class="custom-check -sq"/>
                                    <i class="customInput-display -sq"></i>
                                    <span>
                                        <!-- Abdominal -->
										{{'summary.indicative_way_of_examination.abdominal' | translate | titlecase }}

                                    </span>
        
                                </label>
        
                                <label class="t-label-top">
        
                                    <input type="checkbox"  formControlName="Vaginal" class="custom-check -sq"/>
                                    <i class="customInput-display -sq"></i>
                                    <span>
                                        <!-- Vaginal -->
										{{'summary.indicative_way_of_examination.vaginal' | translate | titlecase }}

                                    </span>
        
                                </label>
        
                                <label class="t-label-top">
        
                                    <input type="checkbox" formControlName="Transperineal" class="custom-check -sq"/>
                                    <i class="customInput-display -sq"></i>
                                    <span>
                                        <!-- Transperineal -->
										{{'summary.indicative_way_of_examination.transperineal' | translate | titlecase }}

                                    </span>
        
                                </label>
                                
                                <label class="t-label-top">
        
                                    <input type="checkbox" formControlName="Rectal" class="custom-check -sq"/>
                                    <i class="customInput-display -sq"></i>
                                    <span>
                                        <!-- Rectal -->
										{{'summary.indicative_way_of_examination.rectal' | translate | titlecase }}
                                    </span>
        
                                </label>
                                                                                                                    
                            </div>
                            
                        </div>
        
			</div>

			<div class="w50 summary-spreadHeight summary-recommendations" *ngIf="es.ExamType === 'Fetal Echo' || es.ExamType === 'Pediatric Echo' ">
				<div class="m-box">

						<h2>
							{{'GUI.Labels.Imaging Quality' | translate}}
						</h2>
	
						<div class="l-flex-c l-flex-w indicative-fieldset h100" style="justify-content: space-evenly;min-height: 2.33em">
							<label class="t-label-top">
		
								<input type="radio" formControlName="ImagingQuality" value="Poor" class="custom-check -sq" />
								<i class="customInput-display -sq"></i>
								<span>
									{{'GUI.Labels.Poor' | translate}}
								</span>
		
							</label>
		
							<label class="t-label-top">
								<input type="radio" formControlName="ImagingQuality" value="Fair" class="custom-check -sq" />
								<i class="customInput-display -sq"></i>
								<span>
									{{'GUI.Labels.Fair' | translate}}
								</span>
							</label>
		
							<label class="t-label-top">
								<input type="radio" formControlName="ImagingQuality" value="Good" class="custom-check -sq" />
								<i class="customInput-display -sq"></i>
								<span>
									{{'GUI.Labels.Good' | translate}}
								</span>
							</label>
						</div>
						
					</div>
	
			

			</div>

			<!-- endcell : recommendations -->
			
			<!-- CELL : DIAGNOSIS -->

			<div class="w50 summary-spreadHeight summary-recommendations">
				<div class="m-box" style='padding-top: 0.5em;padding-bottom: 0.5em;'>

						<h2 style="top: -1em">
							<!-- Referring Doctor -->
							{{'general.Patient.referring_doctor' | translate | titlecase }}

						</h2>
						
						<textarea class="fulltext " style="width: 100%;background: none;border: none;min-height: 3.7em;height: 100%;font-size: inherit;" formControlName="ReferingDoc"></textarea>
						<button class="t-btn-edit" *ngIf="! es.isClosed" (click)="atS.showAuto(summaryForm.get('ReferingDoc'),'Referring Doctor',3)">Auto Text</button>
						
						
					</div>
			
			</div>
			<!-- endcell : diagnosis -->
					
			<!-- cell : Summary -->
			<!-- <div class="w100 summary-boxHeight summary-indicative autoHeight">
				<section class="m-box">
				
					<h2>
						{{'summary.title' | translate | titlecase }}
					</h2>
					<angular-editor  *ngIf="es.ExamType == 'Gynecology' || es.ExamType=='Fetal Echo' || es.ExamType =='Pediatric Echo'" formControlName='Diagnosis' [config]="editorConfig" style="width: 100%;"></angular-editor>

					<textarea class="scroller" style="height: 100%;"  *ngIf="es.ExamType != 'Gynecology' && es.ExamType !=='Fetal Echo' && es.ExamType !=='Pediatric Echo'" formControlName="Diagnosis"></textarea>
					<button class="t-btn-edit" *ngIf="! es.isClosed && lastDiagnosis" (click)="addLastDiagnosis()" style="margin-right: 6em" >Previous Summary</button>
					<button class="t-btn-edit" *ngIf="! es.isClosed" (click)="atS.showAuto(summaryForm.get('Diagnosis'),'Diagnosis',4)">Auto Text</button>


				</section>
			</div> -->
				<div class="w100 summary-recommendations" style="height: 100%;">
					
					<section class="m-box" style="padding-bottom: 0; height: 100%;">
						<h2>
							Summary
						</h2>
						<angular-editor  *ngIf="es.ExamType == 'Gynecology' || es.ExamType === 'Fetal Echo' || es.ExamType === 'Pediatric Echo'" formControlName='Diagnosis' [config]="editorConfig" style="width: 100%;"></angular-editor>
						
						<textarea *ngIf="es.ExamType !== 'Gynecology' && es.ExamType !== 'Fetal Echo' && es.ExamType !== 'Pediatric Echo'" class="scroller scroller-intro fulltext" style="height: 10em;" formControlName="Diagnosis" ></textarea>
						<button class="t-btn-edit" *ngIf="! es.isClosed && lastDiagnosis" (click)="addLastDiagnosis()" style="margin-right: 6em" >Previous Summary</button>
						<button class="t-btn-edit" *ngIf="! es.isClosed" (click)="atS.showAuto(summaryForm.get('Diagnosis'),'Diagnosis',4)">Auto Text</button>
						
					</section>
				</div>
				<!-- <app-text-box *ngIf="showComment" [data]="summaryForm.get('Diagnosis').value" [form]="summaryForm" type="Diagnosis" ATiD="4" (closed)="showComment = false"></app-text-box> -->
			<!-- endcell : Summary -->

			<!-- ================ Diagnosis Tree (Start) ================  -->
			<div *ngIf="ls.premissions.SummaryDiagnosis"
				class="w100 summary-boxHeight summary-indicative" style="height: auto;">
				<app-diagnoses group="summary" [form]="summaryForm.get('DiagnosisCodes')" [formGroup]="summaryForm" insummary="true"
					[text]="summaryForm.get('Anomalies')" class="w100 summary-boxHeight summary-extradocs" autoid="51">
				</app-diagnoses>
			</div>
			<!-- ================ Diagnosis Tree (End) ================  -->

			<!-- ================ Diagnosis (Start) ================  -->
			<div class="w50 summary-recommendations" style="height: fit-content;">
				<section class="m-box">
					<h2>
						Diagnosis
					</h2>
			
					<div style="margin: 0 0 1em 0;">
						<textarea class="scroller" formControlName="DiagnosisGeneral"></textarea>
					</div>
					<button class="t-btn-edit" *ngIf="! es.isClosed"
						(click)="atS.showAuto(summaryForm.get('DiagnosisGeneral'),'Diagnosis',801)">
						Auto Text
					</button>
			
					<div class="sep-8"></div>
			
					<div>
						<div style="display: flex; justify-content: space-between; align-items: center;">
							<h2 style="margin-bottom: 16px; font-size: inherit; font-weight: 400; background: transparent;">
								Diagnosis Comments
							</h2>
							<button *ngIf="!es.isClosed"
								(click)="atS.showAuto(summaryForm.get('DiagnosisGeneralComments'), 'Diagnosis Comments', 802)"
								class="at-btn-edit">
								Auto Text
							</button>
						</div>
						<textarea class="scroller anatomy-comments MH5" formControlName="DiagnosisGeneralComments"></textarea>
					</div>
			
				</section>
			</div>
			<!-- ================ Diagnosis (End) ================  -->

			<!-- cell : indicative way of examination -->
			
			<div class="w50 summary-boxHeight summary-indicative" [ngClass]="(es.ExamType !='CVS' && es.ExamType !='Amniocentesis' && es.ExamType !='Follicles') ? 'autoHeight' : ''">
				<section class="m-box">
	
					<h2>
						<!-- Recommendations -->
						{{'summary.recommendations.title' | translate | titlecase }}

					</h2>
					<textarea class="scroller" formControlName="Recomm"></textarea>
					<button class="t-btn-edit" *ngIf="! es.isClosed && lastRecommendations" (click)="addLastRecomm()"
						style="margin-right: 6em">Previous Recommendations</button>
					<button class="t-btn-edit" *ngIf="! es.isClosed"
						(click)="atS.showAuto(summaryForm.get('Recomm'),'Recommendations',5)">Auto Text</button>

				</section>
			</div>
				
				<div *ngIf="ls.premissions && ls.premissions.FIndings" class="w100 summary-boxHeight summary-indicative"
					style="height: auto;">
				
					<app-findings-summary [formGroup]="summaryForm"></app-findings-summary>
				
				
				</div>

			<!-- CELL : REPEAT EXAMINATION -->
			<div class="summary-repeatExamination w50 summary-boxHeight summary-referringDr">

				<section class="m-box">

					<div class="l-flex-c h100">
						
						<div class="l-fg1 repeatExamination-text" style="display: flex; align-items: center; flex-wrap: wrap;">
							{{'GUI.Labels.Schedule Followup' | translate | titlecase}}
							<select class="t-select t-field" formControlName="FollowUpExam">
								<option></option>
								<ng-container>
									<option value="1st">
										{{'exam.type.OBS.1st' | translate | titlecase}}
									</option>
									<option value="NT">
										{{'exam.type.OBS.NT' | translate | titlecase}}

									</option>
									<option value="Early">
										{{'exam.type.OBS.Early' | translate | titlecase}}
									</option>
									<option value="2nd/3rd">
										{{'exam.type.OBS.2nd/3rd' | translate | titlecase}}
									</option>
									<option value="Late">
										{{'exam.type.OBS.Late' | translate | titlecase}}
									</option>
									<option value="'Third Scan'">
										{{'exam.type.OBS.Third Scan' | translate | titlecase}}
									</option>
									<option value="Specialized">
										{{'exam.type.OBS.Specialized' | translate | titlecase}}

									</option>
									<option value="Fetal Echo">
										{{'exam.type.OBS.Fetal Echo' | translate | titlecase}}

									</option>
									<option value="Pediatric Echo">
										{{'exam.type.OBS.Pediatric Echo' | translate | titlecase}}

									</option>
									<option value="Weight Assessment">
										{{'exam.type.OBS.Weight Assessment' | translate | titlecase}}

									</option>
									<option value="Gynecology">
										{{'exam.type.OBS.Gynecology' | translate | titlecase}}

									</option>
								</ng-container>					
							</select>
						<br/>
						<span>
							&nbsp; 
							{{'summary.in' | translate | titlecase}}
							&nbsp;
						</span>
							<span class="t-highlight-glowingCircle">
								<input type="number" min="1" max="40"  style='width: 2em; background: none; border: none; color: white; font-size: 1.1em;margin-left: .7em;' formControlName="Repeat1">
							</span>
							&nbsp;
							<select class="t-select t-field" formControlName="Repeat2">
								<option></option>
								<option value="days">
									{{'general.weeks.days' | translate | titlecase}}

								</option>
								<option value="weeks">
									{{'general.weeks.weeks' | translate | titlecase}}

								</option>
								<option value="months">
									{{'general.weeks.months' | translate | titlecase}}

								</option>
							</select>
						</div>
					</div>

				</section>



			</div>
		<!-- endcell : referring doctor -->

		<!-- cell : indicative way of examination -->

		

		
		<!-- ENDCELL : REPEAT EXAMINATION -->

		<!-- cell : referring doctor -->

		<div class="w25 summary-boxHeight summary-referringDr">
			<section class="m-box" style="height: 100%;" (dblclick)="summaryForm.get('HMO').setValue(null)">

				<h2>
					<!-- HMO -->
					{{'general.Patient.hmo' | translate | titlecase}}
				</h2>
				<div class="l-flex-c l-flex-w indicative-fieldset h100">
					<label class="t-label-top">

						<input type="radio" formControlName="HMO" value="Leumit" class="custom-check -sq" />
						<i class="customInput-display -sq"></i>
						<span>
							<!-- Leumit -->
							{{'GUI.Selects.Leumit' | translate | titlecase}}
						</span>

					</label>

					<label class="t-label-top">
						<input type="radio" formControlName="HMO" value="Clalit" class="custom-check -sq" />
						<i class="customInput-display -sq"></i>
						<span>
							<!-- Clalit -->
							{{'GUI.Selects.Clalit' | translate | titlecase}}
						</span>
					</label>

					<label class="t-label-top">
						<input type="radio" formControlName="HMO" value="Macabi" class="custom-check -sq" />
						<i class="customInput-display -sq"></i>
						<span>
							<!-- Macabi -->
							{{'GUI.Selects.Maccabi' | translate | titlecase}}
						</span>
					</label>

					<label class="t-label-top">
						<input type="radio" formControlName="HMO" value="Meuhedet" class="custom-check -sq" />
						<i class="customInput-display -sq"></i>
						<span>
							<!-- Meuhedet -->
							{{'GUI.Selects.Meuhedet' | translate | titlecase}}
						</span>
					</label>
					<label class="t-label-top">
						<input type="radio" formControlName="HMO" value="Private" class="custom-check -sq" />
						<i class="customInput-display -sq"></i>
						<span>
							<!-- Private -->
							{{'GUI.Selects.Private' | translate | titlecase}}
						</span>
					</label>
				</div>

			</section>
		</div>

		<div class="w25 summary-boxHeight summary-referringDr">
			<section class="m-box" (dblclick)="summaryForm.get('ResultsRating').setValue(null)">
				<h2>
					{{'summary.ResultsRating.title' | translate | titlecase}}
				</h2>
				<div class="l-flex-h indicative-fieldset h100" style="gap: 1em; display: grid;">
					<label class="t-label-top">

						<input type="radio" formControlName="ResultsRating" value="Normal" class="custom-check" />
						<i class="customInput-display"></i>
						<span>
							{{'summary.ResultsRating.Normal' | translate | titlecase}}
						</span>

					</label>

					<label class="t-label-top">
						<input type="radio" formControlName="ResultsRating" value="Diagnosis" class="custom-check" />
						<i class="customInput-display"></i>
						<span>
							{{'summary.ResultsRating.Diagnosis' | translate | titlecase}}
						</span>
					</label>

					<label class="t-label-top">
						<input type="radio" formControlName="ResultsRating" value="Diagnosis with Followup by Referring Doctor" class="custom-check" />
						<i class="customInput-display"></i>
						<span>
							{{'summary.ResultsRating.Diagnosis with Followup by Referring Doctor' | translate | titlecase}}
						</span>
					</label>

				</div>

			</section>



		</div>
		<!-- endcell : referring doctor -->


		<!-- cell : indicative way of examination -->
		
		<div class="w50 summary-boxHeight summary-indicative" *ngIf="summaryForm.get('HMO').value == 'Leumit' && ls.hasLeumit">
			<section class="m-box">

					<h2>
						PRN & Treatment Code
					</h2>
					<div class="l-flex-c l-flex-w indicative-fieldset h100">
						<label class="t-label-top">
							<span>
								Payer Reference Num
							</span>
							<span class="t-field t-field--number">
								<input class="t-field-text" formControlName="PRN" (keyup)="checklength()" [ngClass]="prnerr? 'is-invalid':''"/>
								<div class="t-validation-message">
									<span>{{prnerr}}</span>
								</div>
							</span>
						</label>

						<label class="t-label-top">
							<span>
								Code of Treatment
							</span>
							<span class="t-field t-field--number">
								<input class="t-field-text" formControlName="TreatmentCode" [ngClass]="trerr? 'is-invalid':''" (keyup)="checklength()"/>
								<div class="t-validation-message">
									<span>Invalid Treament Code</span>
								</div>
							</span>
						</label>

						<button class="save_patieont" (click)="checkPRN()" *ngIf="ls.PRNcheck && checkleumit">
							Check Validity
						</button>
						<div *ngIf="ls.PRNcheck && checkleumit" style="margin-left:auto">Invalid PRNs will not be saved</div>
					</div>
					
				</section>

		</div>
		<div class="w100">
			<section class="m-box">
				<h2>
					{{'summary.Terms' | translate | titlecase}}
				</h2>
				<textarea class="scroller" formControlName="Terms"></textarea>
				<button class="t-btn-edit" *ngIf="!es.isClosed" (click)="atS.showAuto(summaryForm.get('Terms'),'Terms',800)">
					Auto Text
				</button>
			</section>
		</div>
		<!-- cell : 	extra documents -->
		<div class="w100 summary-boxHeight summary-extradocs" *ngIf="false">

			<section class="m-box">

				<h2>
					Extra Documents
				</h2>
				<div class="l-flex-c extraDocuments-table h100">

					<div class="l-flex-c l-flex-w extraDocument-cell-wrap">

						<div class="extraDocument-cell">

							<div class="l-fg1">
								<div class="extraDocument-name">
									Document Name
								</div>
								<div class="extraDocument-date">
									19/12/2017
								</div>
							</div>

							<button class="extraDocument-close"></button>

						</div>


						<div class="extraDocument-cell">

							<div class="l-fg1">
								<div class="extraDocument-name">
									Document Name
								</div>
								<div class="extraDocument-date">
									19/12/2017
								</div>
							</div>

							<button class="extraDocument-close"></button>

						</div>


						<div class="extraDocument-cell">

							<div class="l-fg1">
								<div class="extraDocument-name">
									Document Name
								</div>
								<div class="extraDocument-date">
									19/12/2017
								</div>
							</div>

							<button class="extraDocument-close"></button>

						</div>


						<div class="extraDocument-cell">

							<div class="l-fg1">
								<div class="extraDocument-name">
									Document Name
								</div>
								<div class="extraDocument-date">
									19/12/2017
								</div>
							</div>

							<button class="extraDocument-close" aria-label="remove document"></button>

						</div>

					</div>

					<button class="t-btn-grad">
						Add Extra Documents
					</button>


				</div>

			</section>

		</div>
		<!-- endcell : extra documents -->


	</div>

</main>