import { Injectable } from '@angular/core';
import { formatDate} from '@angular/common';
import { UntypedFormBuilder,UntypedFormGroup,UntypedFormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged,takeUntil } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { PatientsService } from '../app/patients.service';
import { Subject } from 'rxjs';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class PregnancyService {

  public pregForm: UntypedFormGroup;
  debounce=500;//half second
  pregID:number;
  public CervicalMeasures:any=[];
  private unsubscribe$: Subject<void> = new Subject<void>();
  public CreationTime: string;
  public Purpose: UntypedFormControl;
  public Consultation: UntypedFormControl;
  private examid:number;
  private examtime:string;

  constructor(
    private fb: UntypedFormBuilder,
    private ls: LoginService,
    private http: HttpClient,
    private patServe: PatientsService,
  ) {
    this.resetPreg();
    this.Purpose = new UntypedFormControl();
  }

  resetPreg(){
    this.unsubscribe$.next();
    this.pregForm = this.fb.group({
      LMP: [''],
      LMP_US: [''],
      LMP_ULTRASOUND:[''],
      LMPUSdays:  [''],
      LMPUSweeks:  [''],
      Ovulation:  [''],
      NumFetuses:  [1],
      Insitu:  [''],
      CervicalEctop:  [''],
      TubalEctop:  [''],
      OvarianEctop:  [''],
      PeritonealEctop:  [''],
      AbdominalEctop:  [''],
      NaturalPregnancy:  ['-1'],
      IVFType:  [''],
      ChorioAmino: [''],
      NumberOfSacs:  [''],
      MFPR:  [''],
      MFPRfetus:  [''],
      MFPRNotes:  [''],
      TerminationDate: [''],
      TerminationRemarks: [''],
      TerminationType: [''],
      Remarks: [{value:'', disabled: true}],
      Measure: [''], //for cervical measurements
      Funneling: ['-1'],
      Fetus1Sex: [''],
      Fetus1Wt:[''],
      Fetus1Anomolies: [''],
      Fetus2Sex: [''],
      Fetus2Wt:[''],
      Fetus2Anomolies: [''],
      Fetus3Sex: [''],
      Fetus3Wt:[''],
      Fetus3Anomolies: [''],
      Fetus4Sex: [''],
      Fetus4Wt:[''],
      Fetus4Anomolies: [''],
      ClosedDate: [''],
      showHistory:false,
      Para:[''],
      Abortion:[''],
      Gravida:[''],
      PreMedical:[''],
      PreMedicalText:[''],
      PreMedicalComment:[''],
      CVS:[''],
      CVSText:[''],
      CVSComment:[''],
      SurgBack:[''],
      SurgBackText:[''],
      SurgBackComment:[''],
      Drugs:[''],
      DrugsText:[''],
      DrugsComment:[''],
      BloodType:[''],
      Emergency: [''],
      cervicalComment: [''],
      Gravida_US: [''],
      Abortion_US:[''],
      Para_US:[''],
      GA_LMP:[''],
      EDD_US: [''],
      NumFetuses_US: ['']
  });
  }

  setPreg(pregvals,skipall=false,noedit = false,disablehistory=false, close=false){
    if(close)
      this.close()
    else{
      this.resetPreg();
      for (let key in this.pregForm.controls) {
        if(key == 'Measure' || key == 'Funneling')
        continue;
        if(pregvals[key] !== null){
          this.pregForm.get(key).setValue(pregvals[key],{emitEvent: false});
        }
        if(! pregvals["ClosedDate"] && ! noedit && pregvals["ClosedDate"] != "1899-12-31T22:00:00.000Z")
        this.pregForm.get(key).valueChanges.pipe(debounceTime(this.debounce), distinctUntilChanged(),takeUntil(this.unsubscribe$)).subscribe(data=>{
            this.saveVals(key);
        });
      }
      if(! this.pregForm.get('NumFetuses').value)
      this.pregForm.get('NumFetuses').setValue(1,{emitEvent: false});
      // Uncommenting this as a fix for AI-19:
      // if(! this.pregForm.get('LMP').value){
      //   let pat = this.patServe.getPat();
      //   if(pat.LMP){
      //     let now = new Date();
      //     let lmp = new Date(pat.LMP);
      //     if(now.getTime() - lmp.getTime() < 42 * 7 * 24 * 60 * 60 * 1000)
      //     this.pregForm.get("LMP").setValue(pat.LMP);
      //   }
      // }
      if((pregvals["ClosedDate"] && pregvals["ClosedDate"] != "1899-12-31T22:00:00.000Z") || noedit){
        setTimeout(()=>{this.pregForm.disable({emitEvent:false})},1);
        
      }
      else{
        setTimeout(()=>{
          this.pregForm.enable({emitEvent:false});
          if(disablehistory || this.ls.getUserType() != 'doctor')
          this.pregForm.get('Remarks').disable({emitEvent:false});
        },1);
      }
      //add events for cervical measurements
      this.pregForm.get('Measure').valueChanges.pipe(debounceTime(this.debounce), distinctUntilChanged(),takeUntil(this.unsubscribe$)).subscribe(data=>{
        this.saveCervical();
      });
      this.pregForm.get('Funneling').valueChanges.pipe(debounceTime(this.debounce), distinctUntilChanged(),takeUntil(this.unsubscribe$)).subscribe(data=>{
        this.saveCervical();
      });
    }
    if(pregvals["PregIndex"])
      this.pregID=pregvals["PregIndex"];
    else
      this.pregID=pregvals["AutoIndex"];
    this.CreationTime = pregvals["CreationTime"];
    this.getCervicalMeas();
  }

  loadPreg(pregid:number){//for close pregnancy
    this.http.get('/api/getpreg/'+pregid+'/'+this.patServe.getPat().PID).subscribe((data)=>{
      this.setPreg(data);
    }),
    (error)=>{
      console.log(error);
    }
  }

  fetusCt(){
    return this.pregForm.get("NumFetuses").value;
  }

  setExamID(id:number,examdate:string=null){
    this.examid = id;
    this.examtime=examdate
  }

  saveVals(field: string){
    let p = this.patServe.getPat();
    let val = this.pregForm.get(field).value;
    if((field == "LMP" || field == "LMP_US" || field == "Ovulation") && val == '')
      val = null;
    if((field == "LMP" || field == "LMP_US" || field == "Ovulation") && val != null){
      try{
        val = formatDate(this.pregForm.get(field).value,"yyyy-MM-dd","en-US");
      }
      catch{//not a valid date
        return;
      }
    }

    // Fix for AI-19:
    if (field === "TerminationDate" && val === "") {
      return;
    }

    this.http.post('/api/save',
      {
        'table': 'pregnanciesbasic',
        'indexname': 'AutoIndex',
        'indexvalue': this.pregID,
        'PID': p.PID,
        'ID': p.ID,
        'CreationTime': this.CreationTime,
        'field': field,
        'value': val,
        'examid': this.examid
      }
    ).subscribe((data)=>{//later on add observer for saves/errors
      console.log(data);
    }, (error)=> {// do this part later
      console.log(error);
    });
  }

  lmpGA(lmp = this.pregForm.get("LMP").value, refdate=this.examtime){
    if(! lmp)
      return "";
    let dt = new Date(refdate);
    let days = Math.floor((dt.getTime() - Date.parse(lmp))/(1000 * 60 * 60 *24));
    if(Math.floor(days/7) < 1 || Math.floor(days/7) > 50)
      return ""
    return Math.floor(days/7) + "w " + (Math.floor(days) % 7) + "d"
  }
  
  lmpUSGA(lmpus:string=this.pregForm.get("LMP_US").value,weeks=this.pregForm.get("LMPUSweeks").value,edays=this.pregForm.get("LMPUSdays").value,examtime=this.examtime,getDays=false):string{
    if(! lmpus)
      return "";
      let dt = new Date(examtime);
      let days = Math.floor((dt.getTime() - Date.parse(lmpus))/(1000 * 60 * 60 *24));
      if(weeks)
        days+=  weeks * 7;
      if(edays)
        days += edays * 1;
    if(Math.floor(days/7) < 1 || Math.floor(days/7) > 50)
      return ""
      if (getDays) return days +'d' 
      return Math.floor(days/7) + "w " + (Math.floor(days) % 7) + "d";
  }

  fetusUltrasound(){
    return this.pregForm.get("NumFetuses_US").value;
  }

  lmpUS(exam:string,weeks:number,days:number = 0):string{
    if(! exam || ! weeks)
      return null;
    let ms = Date.parse(exam) -(7 * weeks + days)*24*60*60*1000;
    let lmp = new Date(ms);
    return lmp.getDate()+"."+(lmp.getMonth() +1) + "."+lmp.getFullYear();
  }

  getLMP(){
    if(this.pregForm.get("Ovulation").value){
      return new Date(Date.parse(this.pregForm.get("Ovulation").value) - 14*24*60*60*1000)
    }
     
    if(this.pregForm.get("LMP_US").value){
      let days = this.pregForm.get("LMPUSdays").value ? this.pregForm.get("LMPUSdays").value : 0;
      let weeks = this.pregForm.get("LMPUSweeks").value ? this.pregForm.get("LMPUSweeks").value : 0;
      let ms = Date.parse(this.pregForm.get("LMP_US").value) -(7 * weeks + days)*24*60*60*1000;
      return new Date(ms);
    }
  
    return this.pregForm.get("LMP").value;
  }

  USedd(lmpus=this.pregForm.get("LMP_US").value,weeks=this.pregForm.get("LMPUSweeks").value,days=this.pregForm.get("LMPUSdays").value,asTime=false){
    let d=new Date();
    let ov = Date.parse(lmpus);
    d.setTime(ov + (40 * 7 - (weeks* 7 + days * 1)) * 24 *60 * 60 * 1000 );
    if(asTime)
      return d;
    return d.getDate()+"/"+(d.getMonth()+1)+"/"+ d.getFullYear();     
  }

  ovulationGA(ovdate=this.pregForm.get("Ovulation").value,refdate=this.examtime){
    if(! ovdate)
      return "";  
    let dt = new Date(refdate);
    let days = Math.round((dt.getTime() - Date.parse(ovdate))/(1000 * 60 * 60 *24)) + 14;
    return Math.floor(days/7) + "w " + (Math.floor(days) % 7) + "d"
  }

  ovulationLMP(ovdate=this.pregForm.get("Ovulation").value):string{
    if(! ovdate)
      return "";
    let lmp = new Date(Date.parse(ovdate) - 14*24*60*60*1000);
    return lmp.getDate()+"."+(lmp.getMonth() +1) + "."+lmp.getFullYear();
  }

  getGA(refdate = this.examtime){
    let ga = this.ovulationGA(this.pregForm.get("Ovulation").value,refdate)
    if(ga != "")
      return ga;
    ga = this.lmpUSGA(this.pregForm.get("LMP_US").value,this.pregForm.get("LMPUSweeks").value,this.pregForm.get("LMPUSdays").value,refdate)
    if(ga != "")
      return ga;
    return this.lmpGA(this.pregForm.get("LMP").value, refdate);
  }

  eddOvolation(ovulat=this.pregForm.get("Ovulation").value,asTime=false){
    if(! ovulat)
      return "";
    let d = new Date();
    let ov = Date.parse(ovulat);
    d.setTime(ov + 38*7*24*60*60*1000);
    if(asTime)
      return d;
    return d.getDate()+"/"+(d.getMonth()+1)+"/"+ d.getFullYear();    
  }

  edd(asTime = false,lmp=""){
    let d = new Date();
    if(lmp!=""){
      let dt = Date.parse(lmp);
      d.setTime(dt + 40*7*24*60*60*1000);
      if(asTime)
        return d;
      return d.getDate()+"/"+(d.getMonth()+1)+"/"+ d.getFullYear(); 
    }
    if(this.pregForm.get("Ovulation").value){
      return this.eddOvolation(this.pregForm.get("Ovulation").value,asTime);
    }
    if(this.pregForm.get("LMP_US").value){
      return this.USedd(this.pregForm.get("LMP_US").value,this.pregForm.get("LMPUSweeks").value,this.pregForm.get("LMPUSdays").value,asTime); 
    }
    if(! this.pregForm.get("LMP").value)
      return "";
    let ov = Date.parse(this.pregForm.get("LMP").value);
    d.setTime(ov + 40*7*24*60*60*1000);
    if(this.lmpGA() == '')
      return "";
    if(asTime)
      return d;
    return d.getDate()+"/"+(d.getMonth()+1)+"/"+ d.getFullYear();      
  }

  getCervicalMeas(){
    if(this.pregID)//gnu doesn't have
      return this.http.get("/api/cervicalmeas",{params: {PregID: this.pregID.toString(), PID: this.patServe.getPat().PID.toString() }}).subscribe((data)=>{
        this.CervicalMeasures = data;
        var now = new Date().toISOString().split("T")[0];
        if(this.CervicalMeasures.length > 0 && now == this.CervicalMeasures[0].MeasureDate.split(" ")[0]){
          this.pregForm.get("Funneling").setValue(this.CervicalMeasures[0].Funneling.toString(), {emitEvent: false});
          this.pregForm.get("Measure").setValue(this.CervicalMeasures[0].Measure, {emitEvent: false});
        }

      },(error)=>{
        console.log(error);
      });
  }

  saveCervical(){
    this.http.get("/api/cervicalmeas/save",{params:{PregID: this.pregID.toString(),
      PID: this.patServe.getPat().PID.toString(),
      Measure: this.pregForm.get("Measure").value,
      Funneling: this.pregForm.get("Funneling").value,
      GaByLmp : this.getGA(),
      cervicalComment: this.pregForm.get("cervicalComment").value
    }}).subscribe((data)=>{//later on add observer for saves/errors
      this.getCervicalMeas();
    }, (error)=> {// do this part later
      console.log(error);
    });
  }

  getFetusCounter(){
    let ct = []
    for(let i=1; i <= this.pregForm.get("NumFetuses").value;i++){
      ct.push(i);
    }
    return ct;
  }

  getTermWeeks(){
    let tm: number;
    if(! this.pregForm.get("TerminationDate").value)
      return null;
    let term = Date.parse(this.pregForm.get("TerminationDate").value);
    if(this.pregForm.get("Ovulation").value){
      tm = Date.parse(this.pregForm.get("Ovulation").value)-14*24*60*60*1000;    
      return Math.round((term -tm) / (7 * 24 *60 *60 *100))/10;
    }
    if(this.pregForm.get("LMP_US").value){
      tm = Date.parse(this.pregForm.get("LMP_US").value);
      tm -=   (this.pregForm.get("LMPUSweeks").value* 7 + this.pregForm.get("LMPUSdays").value) * 24 *60 * 60 * 1000 ;   
      return Math.round((term -tm) / (7 * 24 *60 *60 *100))/10;
    }
    if(! this.pregForm.get("LMP").value)
      return null;
    tm = Date.parse(this.pregForm.get("LMP").value);
    return Math.round((term -tm) / (7 * 24 *60 *60 *100))/10; 
  }

  close(){
    let res = this.http.get('/api/closepregnancy',
      {params:{'pregid': this.pregID.toString(),'PID':this.patServe.getPat().PID}}
    );
    res.subscribe((data)=>{
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
      this.pregForm.disable({emitEvent:false});
    },(error)=> {
      console.log(error);
    });
    return res;
    
  }
}


