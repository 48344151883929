import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortByGa'
})
export class SortByGaPipe implements PipeTransform {

  transform(pregnancies: any[], lmpField: string = 'LMP'): any[] {
    if (!pregnancies || !Array.isArray(pregnancies)) {
      return pregnancies;
    }

    return pregnancies.sort((a, b) => {
      const gaA = this.calculateGA(a[lmpField]);
      const gaB = this.calculateGA(b[lmpField]);

      // Sort by GA (smallest to largest)
      return gaA - gaB;
    });
  }

  private calculateGA(lmp: string | null): number {
    if (!lmp) return Number.MAX_SAFE_INTEGER;

    const currentDate = new Date();
    const lmpDate = new Date(lmp);
    const daysDifference = Math.floor((currentDate.getTime() - lmpDate.getTime()) / (1000 * 60 * 60 * 24));

    // Convert days to total GA in days for comparison
    if (Math.floor(daysDifference / 7) < 1 || Math.floor(daysDifference / 7) > 50) {
      return Number.MAX_SAFE_INTEGER; // Treat invalid GA as the largest
    }

    return daysDifference;
  }
}