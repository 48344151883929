<div  class="print-history-popup" [formGroup]="FindingForm">
    <div class="print-history-popup-body">
      <h2 class="popup-title">
    Add New Finding
    </h2>
    <div >
                <button
                (click)="closeAddNewFinding()"
                  class="print-history-popup-close"
                  aria-label="close popup"
                ></button>
        <div style="    width: 100%;
        float: right;">
            <div class="l-flex l-flex-c t-field t-field-pad">
                <!-- <i class="t-sep"></i> -->
                <input type="text" class="t-field-text w100" formControlName="Finding" />
                <!-- <label style="white-space: nowrap;">
                 Finding
                </label> -->
            </div>
          </div>
        <button class="blue-btn" [disabled]="!FindingForm.get('Finding').value" (click)="saveNewFinding()" >
            {{'appointments.gui.buttons.Save New' | translate}}
        </button>
    </div>
    </div>
  </div>