import { Component, OnInit } from "@angular/core";
import { LoginService } from "../login.service";
import { AdvancedSearch } from "../AdvencedSearch.service";
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";

@Component({
  selector: "app-bottombar",
  templateUrl: "./bottombar.component.html",
  styleUrls: ["./bottombar.component.scss"],
})
export class BottombarComponent implements OnInit {
  showSearchPopup = false;
  showButton = false;
  currentDate = new Date();
  currentYear = this.currentDate.getFullYear();

  constructor(
    public loginService: LoginService,
    public bottomService: AdvancedSearch,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {}

  disableDataCenter() {
    const currentRoute = this.route.snapshot.routeConfig.path;

    if (this.loginService.premissions && this.loginService.premissions.searchPregnancies && currentRoute == "patsearch") {
      return false;
    }
    if (this.loginService.premissions && this.loginService.premissions.FIndings && currentRoute == "patsearch") {
      return false;
    }
    return true;
  }

  goAdvancedSearch() {
    this.router.navigate(["/advanced-search"]);
  }

  goReportsLeumit() {
    this.router.navigate(["/admin-Leumit-Report"]);
  }

  goMediaTransfer() {
    this.router.navigate(["/media-transfer"]);
  }

  goInboxTransfer() {
    this.router.navigate(["/inbox-transfer"]);
  }

  goMediaTransferHistory() {
    this.router.navigate(["/transfer-history/media"]);
  }

  goInboxTransferHistory() {
    this.router.navigate(["/transfer-history/inbox"]);
  }
}
