<section class="m-box">
    <app-add-finding *ngIf="addNewFinding" (closed)="onClosed()"></app-add-finding>
    <h2>
        <!-- FIndings -->
        Findings
        <!-- {{'summary.recommendations.title' | translate | titlecase }} -->

    </h2>
    <div style="display: flex;flex-direction: column;float: inline-end;margin: auto;">
        <button style="margin: auto;
   border: 1px solid rgb(39,115,130);
   float: right;" matTooltip="add diagnosis" class="advanced-search-btn" type="button" (click)="addNewFinding=true">
            Create New Finding
        </button>
    </div>
    <div style="display: flex;">
    <div class="search-wrapper" style="width: 90%;">
        <mat-form-field class="border-red" style="width: 100%;">
            <mat-label>Search for a Finding </mat-label>
            <input class="search-input" type="text" matInput [matAutocomplete]="auto" [(ngModel)]="FindingText"
                (ngModelChange)="filterOptions()" />
            <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let option of filteredFindings" [value]="option['Finding']">
                    {{ option['Finding'] }}

                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </div>
    <button style="margin: auto;
                   border: 1px solid rgb(39,115,130);
                   float: right;" matTooltip="add diagnosis" class="advanced-search-btn" type="button"
        *ngIf="selectedFindingOpenSearch && !es.isClosed" (click)="addFinding()">
        Add Finding
    </button>
</div>
<ng-container *ngFor="let finding of examFindings">
    <div
    style="cursor: pointer;"
    (dblclick)="!es.isClosed ?removeFinding(finding):null"
      title="Double clicking on this will remove it"
    >
      {{ finding['Finding'] }}
    </div>
  </ng-container>
  <div style="margin-top: 5%;display: flex;justify-content: space-between;align-items: center;">
  <h2
  style="
    margin-bottom: 16px;
    font-size: inherit;
    font-weight: 400;
    background: transparent;
 "
>
  Findings Comments
</h2>
<button
  class="t-btn-edit"
  *ngIf="!es.isClosed"
  (click)="
    atS.showAuto(formGroup.get('findingsComments'), 'Findings Comments', 1574)
  "
>
  Auto Text
</button>
</div>

<textarea
  class="scroller anatomy-comments MH5"
  [formControl]="formGroup.get('findingsComments')"
></textarea>

</section>
