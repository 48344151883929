import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
} from "@angular/core";
import { UntypedFormGroup,UntypedFormBuilder } from "@angular/forms";
import { HttpClient } from '@angular/common/http';
import { LoginService } from "../login.service";

@Component({
  selector: 'app-add-finding',
  templateUrl: './add-finding.component.html',
  styleUrls: ['./add-finding.component.scss']
})
export class AddFindingComponent implements OnInit {
  @Output() closed = new EventEmitter();
  FindingForm:UntypedFormGroup
  constructor( private fb:UntypedFormBuilder, private http: HttpClient, public ls:LoginService) { }

  ngOnInit(): void {
    this.FindingForm = this.fb.group({
      Finding:['']
    });
  }

  closeAddNewFinding(){
    this.closed.emit()
  }

  saveNewFinding(){
    this.http.post('/api/Findings',this.FindingForm.value).subscribe((data: string) => {
   this.closeAddNewFinding()
    })
  }
}
