import { AdvancedSearch } from '../AdvencedSearch.service';
import { DiagnosisService } from "../diagnosis.service";
import { Component, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ExamsService } from "../exams.service";
import { LoginService } from "../login.service";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { PatientsService } from "../patients.service";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { writeFile } from 'xlsx';
import { DatePipe } from '@angular/common';


@Component({
  selector: "app-advanced-search",
  templateUrl: "./advanced-search.component.html",
  styleUrls: ["./advanced-search.component.scss"],
})
export class AdvancedSearchComponent implements OnInit {
searchType: string ;
pregnancySearchForm: FormGroup;
currentDate: Date;
FindingText=''
selectedFindingOpenSearch=false
filteredFindings=[]
examFindings :any = []
userFindings=[]
  constructor(
    private http: HttpClient,
    public examService: ExamsService,
    public loginService: LoginService,
    public AdvancedSearch: AdvancedSearch,
    public patientsService: PatientsService,
    public diagnosisService: DiagnosisService,
    private router: Router,
    private fb: FormBuilder,
    public es: ExamsService
  ) {
    this.loginService.loginMissing();
  }

  ngOnInit(): void {

    this.currentDate = new Date();
    
    if(this.loginService.premissions && this.loginService.premissions.searchPregnancies){
      this.searchType ="Pregnancies";
      this.loadPregnanciesSearchForm()
      // get last request updated
      if(this.AdvancedSearch.PregnanciesResult && this.AdvancedSearch.PregnanciesResult['data']){
        const lastRequestData= this.AdvancedSearch.PregnanciesResult['data']
        this.searchPregnanciesRequest(lastRequestData)
      }

    }
   else if(this.loginService.premissions && this.loginService.premissions.FIndings){
      this.searchType ="findings";
      this.loadFindingsOptions(); 
    }
  }


  
  setSearchType(searchName: string){  
    this.searchType=searchName;
    if(searchName=='Pregnancies'){
      this.loadPregnanciesSearchForm()
    }
    else if(searchName=='findings'){
      this.loadFindingsOptions(); 
    }
  }

  

  loadFindingsOptions(){
    this.es.getFindingsOptions().subscribe( {
      next: (data:any) => {
        this.userFindings = data;
        this.filteredFindings = this.userFindings || []
      },
      error: (err) => console.error('Error fetching options:', err),
    });
    }

  loadPregnanciesSearchForm(){
    this.pregnancySearchForm = this.fb.group({
      pregStatus: ['open'], 
      gaOption: ['equal', Validators.required] ,
      gaFromW: [null, [Validators.min(0)]],
      gaFromD: [null, [Validators.min(0)]],
      gaToW: [null, [Validators.min(0)]],
      gaToD: [null, [Validators.min(0)]],
      gaLessW: [null, [Validators.min(0)]],
      gaLessD: [null, [Validators.min(0)]],
      gaMoreW: [null, [Validators.min(0)]],
      gaMoreD: [null, [Validators.min(0)]],

    });
    this.pregnancySearchForm.get('gaOption')?.valueChanges.subscribe(() => {
      this.pregnancySearchForm.patchValue({
        gaFromW: null,
        gaFromD: null,
        gaToW: null,
        gaToD: null,
        gaLessW: null,
        gaLessD: null,
        gaMoreW: null,
        gaMoreD: null,
      });
    });
    
  }
  
  searchPregnancies() {
    if (this.pregnancySearchForm.invalid) {
      console.log("Form is invalid. Please correct the errors.");
      return;
    }


  
    const formValues = this.pregnancySearchForm.value;
  
    // Prepare the search payload
    const searchPayload: any = {
      pregStatus: formValues.pregStatus,
      gaOption: formValues.gaOption,
    };
  
    // Add GA criteria based on selected option
    switch (formValues.gaOption) {
      case 'equal':
        searchPayload.gaFrom = {
          weeks: formValues.gaFromW || 0,
          days: formValues.gaFromD || 0,
        };
        searchPayload.gaTo = {
          weeks: formValues.gaToW || 0,
          days: formValues.gaToD || 0,
        };
        break;
      case 'less':
        searchPayload.gaLess = {
          weeks: formValues.gaLessW || 0,
          days: formValues.gaLessD || 0,
        };
        break;
      case 'more':
        searchPayload.gaMore = {
          weeks: formValues.gaMoreW || 0,
          days: formValues.gaMoreD || 0,
        };
        break;
      default:
        break;
    }
  
  
    this.AdvancedSearch.searchPregnancies(searchPayload).subscribe(
      (response) => {
        this.AdvancedSearch.PregnanciesResult={};
        this.AdvancedSearch.PregnanciesResult['results']=response['results']
        this.AdvancedSearch.PregnanciesResult['data']=searchPayload
        this.sortPregnancies('asc')
     
      },
      (error) => {
        this.AdvancedSearch.PregnanciesResult=null
      
      }
    );
  }

  searchPregnanciesRequest(searchPayload){
    this.AdvancedSearch.searchPregnancies(searchPayload).subscribe(
      (response) => {
        this.AdvancedSearch.PregnanciesResult={};
        this.AdvancedSearch.PregnanciesResult['results']=response['results']
        this.AdvancedSearch.PregnanciesResult['data']=searchPayload
        this.sortPregnancies('asc')
       
      },
      (error) => {
        this.AdvancedSearch.PregnanciesResult=null
      
      }
    );
  }

  canSearchPreg(): boolean {
    const formValues = this.pregnancySearchForm.value;
  
    const isDaysValid = (days: number | null): boolean => {
      return days != null && days <= 6;
    };
    
    if (formValues.gaOption === 'equal') {
      return (
        (formValues.gaFromW != null && isDaysValid(formValues.gaFromD)) &&
        (formValues.gaToW != null && isDaysValid(formValues.gaToD))
      );
    }
  
    if (formValues.gaOption === 'less') {
      return (
        formValues.gaLessW != null && isDaysValid(formValues.gaLessD)
      );
    }
  
    if (formValues.gaOption === 'more') {
      return (
        formValues.gaMoreW != null && isDaysValid(formValues.gaMoreD)
      );
    }
    

    return true;
  }

  
  exit() {
    this.router.navigateByUrl("/patsearch");
    this.AdvancedSearch.clearLogout();
  }

  lmpGA(lmp: string, closedDate?: string): string {
  if (!lmp) return "";

  const endDate = closedDate ? new Date(closedDate) : new Date();
  const startDate = new Date(lmp);

  
  const days = Math.floor((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24));

  return `${Math.floor(days / 7)}w ${days % 7}d`;
}



  
  generatePregsExcelFile(pregs) {
    const datePipe = new DatePipe('en-US');

    const searchData = this.AdvancedSearch?.PregnanciesResult?.['data'];
    let searchRange = 'Search Range: ';
    if (searchData) {
      if (searchData['gaOption'] === 'equal') {
        searchRange += `from: ${searchData['gaFrom']['weeks']}w${searchData['gaFrom']['days']}d to: ${searchData['gaTo']['weeks']}w${searchData['gaTo']['days']}d`;
      } else if (searchData['gaOption'] === 'less') {
        searchRange += `GA less than: ${searchData['gaLess']['weeks']}w${searchData['gaLess']['days']}d`;
      } else if (searchData['gaOption'] === 'more') {
        searchRange += `GA more than: ${searchData['gaMore']['weeks']}w${searchData['gaMore']['days']}d`;
      }
    }

    let PregnancyStatus = 'Pregnancy status: ';
    if (searchData) {
      PregnancyStatus += searchData['pregStatus'] === 'open' ? 'Open' :searchData['pregStatus'] === 'close'? 'Closed':'Both';
    }
  
    const filteredData = pregs.map(item => ({
        'GA': this.lmpGA(item.LMP, item.ClosedDate) , 
        'ID': item.ID,
        'NAME': `${item.First || ''} ${item.Last || ''}`,
        'Pregnancy status':  item.ClosedDate ? 'Closed' : 'Open' ,
        'Phone':item.Phone
    }));

    const sheetName = 'pregnanciesList';
    const fileName = `pregnancies generated list from ${datePipe.transform(this.currentDate, 'dd-MM-yyyy')}.xlsx`;

    const worksheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(worksheet, [[searchRange]], { origin: 'A1' });
    XLSX.utils.sheet_add_aoa(worksheet, [[PregnancyStatus]], { origin: 'A2' });

    worksheet['!cols'] = [{ wch: 30 }]; 
    XLSX.utils.sheet_add_json(worksheet, filteredData, { origin: 'A3' }); 
    const columnCount = Object.keys(filteredData[0] || {}).length;
    worksheet['!cols'] = Array(columnCount).fill({ wch: 30 });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    writeFile(workbook, fileName);
  }

  clearSearch(){
    this.pregnancySearchForm.patchValue({
      gaFromW: null,
      gaFromD: null,
      gaToW: null,
      gaToD: null,
      gaLessW: null,
      gaLessD: null,
      gaMoreW: null,
      gaMoreD: null,
    });
  }

  sortPregnancies(order: 'asc' | 'desc'): void {
    if(!this.AdvancedSearch.PregnanciesResult.results ||this.AdvancedSearch.PregnanciesResult.results.length === 0) return;
    this.AdvancedSearch.PregnanciesResult.results.sort((a, b) => {
      const gaA = this.calculateGA(a.LMP, a.ClosedDate);
      const gaB = this.calculateGA(b.LMP, b.ClosedDate);
  
      if (order === 'asc') {
        return gaA - gaB;
      } else {
        return gaB - gaA;
      }
    });
  }
  
  calculateGA(lmp: string, closedDate?: string): number {
    if (!lmp) return 0;
  
    const endDate = closedDate ? new Date(closedDate) : new Date();
    const startDate = new Date(lmp);
  
    return Math.floor((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24));
  }

  filterOptions(){
    this.selectedFindingOpenSearch = false;
    if (this.FindingText) {
     
      this.filteredFindings = this.userFindings.filter((option) =>{
       return option['Finding'].toLowerCase().includes(this.FindingText.toLowerCase())
      }
      );
      const searchMatchFindings = this.filteredFindings.filter(
        (option) =>{return option['Finding'].toLowerCase() === this.FindingText.toLowerCase()}
      );
      if (searchMatchFindings.length) {
        this.selectedFindingOpenSearch=true
        
      }
    } else {
      this.filteredFindings = this.userFindings || [];
      this.selectedFindingOpenSearch=false
      
    }
  }

  searchExamsByFreeText(){
    this.AdvancedSearch.searchExamsByFreeText(this.FindingText).subscribe(
      (response) => {
        this.AdvancedSearch.examsFindings = response as [];
      },
      (error) => {
        this.AdvancedSearch.examsFindings = [];
      }
    );
  }

  goExam(exam){
    this.patientsService.getPatient(exam.PID, (s) => {
      if (s) {
      

        const preg={ AutoIndex: exam.PregIndex }
        this.examService.goExam(preg, exam, exam.ExamType, exam.PID);
      }
    });
 
  }

  generateExcelFileFindings() {
    let diagnosisFullString;
    if (this.FindingText) {
      diagnosisFullString = this.FindingText;
    } else {
      diagnosisFullString = "All";
    }
    

    const organizedData = this.AdvancedSearch.examsFindings.map((exam:any) => {

      const creationDate = new Date(exam.CreationTime);
      const formatDate = `${String(creationDate.getDate()).padStart(2, '0')}.${String(creationDate.getMonth() + 1).padStart(2, '0')}.${creationDate.getFullYear()} ${String(creationDate.getHours()).padStart(2, '0')}:${String(creationDate.getMinutes()).padStart(2, '0')}`;
      return {
        "Patient Name": `${exam.First} ${exam.Last}`,
        "Exam Type": exam.ExamType,
        "Exam Time": formatDate,
        "Done By": exam.OpenerName,
        "Finding": diagnosisFullString,
      };
    });

    const worksheet = XLSX.utils.json_to_sheet(organizedData);

    worksheet['!cols'] = [
      { width: 15 }, 
      { width: 15 }, 
      { width: 25 }, 
      { width: 20 }, 
      { width: 30 }, 
    ];
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth() + 1;
    const day = now.getDate();
    const dateOnly = `${day}-${month < 10 ? '0' : ''}${month}-${year}`;
    saveAs(
      blob,
      `(AWEB Data Center) Search results for - ${diagnosisFullString} ${dateOnly}.xlsx`
    );
  }


}
