import { Injectable } from "@angular/core";
import { LoginService } from "./login.service";
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: "root",
})
export class AdvancedSearch {
  PregnanciesResult
  examsFindings
  constructor(private ls: LoginService,private http: HttpClient) {
  }



  searchPregnancies(payload: any): Observable<any> {
    return this.http.post<any>(`/api/search/pregnancies`, payload);
  }

  clearLogout() {
  this.PregnanciesResult=null
  this.examsFindings=null
  }

  searchExamsByFreeText(Funding: string): Observable<any> {
    return this.http.get<any>(`/api/Findings/exams/${Funding}`);
  }
}
