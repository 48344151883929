import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import {PregnancyService} from '../app/pregnancy.service';
import {PatientsService} from '../app/patients.service';
import { UntypedFormBuilder,UntypedFormGroup,UntypedFormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import {Measure} from './measurements/meaure';
import { Subject, Subscription, BehaviorSubject } from 'rxjs';
import { DomSanitizer} from '@angular/platform-browser';
import {SafeUrl} from '@angular/platform-browser';
import {LoginService} from './login.service';
import moment from 'moment';


@Injectable({
  providedIn: 'root'
})
export class ExamsService implements OnDestroy {
  searchReport;
  pregId: number;
  examID: number;
  CreationTime: string;
  isClosed = false;
  LinkSent = false;
  debounce=500;
  table: string;
  ExamType: string;
  IgnoreFetus = false; 
  isGastro=false;
  GASTRO=['Colonoscopy', 'Gastroscopy', 'Sigmoidoscopy'];
  private Fetus = 1;
  public dataReceived$: Subject<void> = new Subject<void>();
  public fetusChange$: Subject<number> = new Subject<number>();
  public formLoaded$: Subject<void> = new Subject<void>();
  public examLoaded$: Subject<void> = new Subject<void>();
  public mediaArrived$: Subject<string> = new Subject<string>();
  public consentSaved$: Subject<any> = new Subject<any>();
  private continueExamSource = new Subject<boolean>();

  continueExam$ = this.continueExamSource.asObservable();

  stopRecording:SafeUrl;
  ShowSRs=false;
  SRList: number[];
  AWLID: number;
  AWLlmp:Date;
  public Defaults:number;
  public PregCopied:boolean;
  public isForm:boolean;
  public weightAssAll: any;
  weightSub: Subscription;
  formSubs: Subscription[] = [];
  changedTitle = false;
  titleChange: Subject<boolean> = new Subject<boolean>();
  newTitle = "";
  CustomTitlesChanged =  new Subject<any>();
  ws: WebSocket;

  CustomTitles:any;
  newTitleItem: UntypedFormGroup;
  disableSaveTitle= false;
  signed=false;
  public OpenerName: string;
  public printAllMeas = new UntypedFormControl(true);
  switchNT = false;
  switchOvaries = false;
  addNT = false;
  NTAdd$ = new Subject();
  concentLoaded$ = new Subject();
  concentForm;
  pregnancies;
  _saving = false;
  _save_queue = [];
  openNewExam = false;
  awaitPrintHistory: boolean = false;
  examsUsMode=['Early','2nd/3rd','Weight Assessment','Late','Third Scan', 'Specialized','Tech Exam','CVS','Amniocentesis'];

  // Consent Form:
  consentExamType: string = "";
  consentDocUID: number;
  consentPractitioner: string = "";
  loadPrevDocSign: boolean = false;
  modality:string;

  audio = new Audio('../assets/sounds/chime.mp3');

  public isEitherLMPDataMissing: boolean = false
  		  
  private isEitherLMPDataMissingSource = new BehaviorSubject<boolean>(false);
  public isEitherLMPDataMissing$ = this.isEitherLMPDataMissingSource.asObservable();
 
  public setEitherLMPDataMissing(value: boolean): void {
    this.isEitherLMPDataMissing = value;
    this.isEitherLMPDataMissingSource.next(value);
  }

  public shouldAutoFocus$: Subject<boolean> = new Subject<boolean>();
  public shouldAutoFocus: boolean = false;

  public shouldTranslate$: Subject<boolean> = new Subject<boolean>(); 

  constructor(
    private fb: UntypedFormBuilder,
    private http: HttpClient,
    private router: Router,
    public ps: PregnancyService,
    public patServ: PatientsService,
    private sanitizer: DomSanitizer,
    private login: LoginService,
  ) {
    this.ps.Purpose.valueChanges.pipe(debounceTime(this.debounce), distinctUntilChanged()).subscribe(data=>{
      this.saveVals("PurposeOfExam",this.ps.Purpose.value,false,"exams");
    }); 
    this.weightSub = this.fetusChange$.subscribe((res) => {
      this.getAllWeightAssByPre(this.ps.pregID, this.CreationTime, res);
    })
    this.newTitleItem = this.fb.group({
      AutoIndex: new UntypedFormControl(),
      UID: new UntypedFormControl(),
      GID: new UntypedFormControl(),
      Title:  new UntypedFormControl(),
      Creation:  new UntypedFormControl(),
      isUpdate: new UntypedFormControl(),
    });
    this.CustomTitlesChanged.subscribe(
      (data) => {
        this.CustomTitles = data;
        this.disableSaveTitle = this.CustomTitles.length >= 8
      }
    )
  }
  getCustomTitles(){
      this.http.get(`/api/newExams/getCustomTitle`).subscribe((data:any) => {
        this.CustomTitlesChanged.next(data)
      })
  }

  saveNewTitle(GID:number, update:boolean){
    if(! this.disableSaveTitle){
      this.newTitleItem.get('isUpdate').patchValue(this.newTitleItem.get('AutoIndex').value !== null);
      this.newTitleItem.get('GID').patchValue(GID);
      this.http.post('/api/newExams/saveCustomTitle',{newTitle: this.newTitleItem.value}).subscribe(
        (data:any) =>{
          if(data.message ==='ok'){
            this.getCustomTitles();
            this.newTitleItem.reset({emitEvent:false})
          }
        }
      )
    }
  }
  resetTitleItem(){
    this.newTitleItem.reset({eventEmit:false});
  }
  getTitle(index:number){
    var title=""
    this.CustomTitles.forEach((c:any) => {
      if(c.AutoIndex === index){
        this.newTitleItem.patchValue(c);
      }
    })
  }
  deleteTitle(index:number){
    this.http.delete(`/api/newExams/title/${index}`).subscribe(
      (data:any)=>{
        if(data.message ==='ok'){
          this.getCustomTitles();
          this.newTitleItem.reset({emitEvent:false})
        }
      }
    )
  }
  ngOnDestroy(){
    this.weightSub.unsubscribe();
  }

  getExams(patid: string){
    return this.http.get("/api/exams",{params: {PID: patid, uid:this.login.doctor,ID:this.patServ.getPat().ID }});
  }

  getDefaults(){
    return this.http.get("/api/defaults");
  }

  setFetus(fetusnum: number){
    let changed = false;
    if(fetusnum != this.Fetus)
      changed = true;
    this.Fetus=fetusnum;
    if(changed)
      this.fetusChange$.next(fetusnum);
  }

  getFetus():number{
    return this.Fetus;
  }

  getSRs(){
    this.http.get('/api/srlist/'+ this.examID).subscribe((data:number[])=>{
      this.SRList = data;
      if(this.SRList.length > 0)
        this.ShowSRs=true;
    },(error)=>{
      console.log(error);
    });
  }

  getSR(sr:number){
    return this.http.get('/api/getsr/' + sr,{responseType: 'text'});
  }

  setForm(){
    switch(this.ExamType){
      case 'Consultation Letter':
        this.isForm = true;
      break;
      default:
        this.isForm=false;
      break;
    }
  }

  startExam(name: string, patid: number, tz: string,AWL: number = null, lmp:Date = null, concent = null){
    this.ExamType=name;
    this.isGastro = this.GASTRO.includes(name);
    this.setForm();
    this.http.post("/api/openexam",{PID: patid, ID: tz, ExamType: name, AWLID: AWL}).subscribe((data)=>{
      
      this.pregId = data["pregnanciesbasic"]["AutoIndex"];
      this.examID = data["exam"]["AutoIndex"];

      if(!this.login.ishospital && concent && this.login.premissions.Consent){
        this.concentForm = concent;
        this.concentForm.ExamID = this.examID
        this.concentForm.PregID = this.pregId;
        this.saveForm(true)
      }
      if(this.ExamType !== 'Consultation Letter' && (!this.concentForm || ! this.concentForm.ExamID) && this.login.premissions.Consent)
        this.setConcentExmID().subscribe((data)=>{
          console.log(data);

          this.concentForm['ExamID'] = this.examID ?? null;
          this.concentForm['PregID'] = this.pregId ?? null;
          
        })
      
      this.changedTitle = false;
      this.switchNT = false;
      this.switchOvaries = false;
      this.addNT=false;
      this.Defaults=null;
      this.setPurpose("");
      this.ps.Purpose.enable({emitEvent:false});
      this.CreationTime = data["exam"]["CreationTime"];
      if(name != "Follicles" && name != "Gynecology" && ! this.login.isCardio && patid > 0 && !this.isForm && !this.login.isGastro){
        this.ps.setPreg(data["pregnanciesbasic"],false,false);
        this.ps.setExamID(this.examID,this.CreationTime);
      }
      this.isClosed=false;
      this.Fetus=1;
      this.signed = false;
      
      this.AWLlmp=lmp;
      this.router.navigate(['/exam']);
      this.AWLID = AWL;
      this.examLoaded$.next();
      if(this.login.localServer && (! this.login.lmp_in_mwl || name == "Follicles" || name == "Gynecology"))
        this.relayWorklist();
      this.openSocket();
    }, (error)=> {// do this part later
      console.log(error);
    });
  }
  setConcentExmID(){
    if(this.concentForm && (this.concentForm.ExamType == this.ExamType))
      return this.http.post('/api/letters/saveExamID', {examID: this.examID, pregID:this.pregId,concentID: this.concentForm.AutoIndex})
    else
      return this.http.post('/api/letters/saveExamID', {examID: this.examID, ExamType: this.ExamType, PregID:this.pregId})
  }

  private setPurpose(value){
    this.ps.Purpose.setValue(value,{emitEvent:false});
    
  }

  goExam(preg, exam,etype:string,viewonly = false){
    
    if(! exam){
      return;
    }
    if(preg)
      this.pregId = preg.AutoIndex;
    else
      this.pregId = null;
    this.examID = exam.AutoIndex;

    this.Fetus=1;
    this.router.navigate(['/exam']);
    let pid = this.patServ.getPat().PID
    this.ExamType=etype;
    this.isGastro = this.GASTRO.includes(etype);
    if(this.ExamType == 'Emergency Room')
      viewonly = true;

    this.setForm();
    this.http.get("/api/exam", {params: {'ExamID': this.examID.toString(), 'PregIndex': this.pregId ? this.pregId.toString():'', 'PID': pid.toString(), 'viewonly': (viewonly? 'y':'n')}} ).subscribe((data)=>{
      
      this.CreationTime = data["exam"]["CreationTime"];
      this.ExamType=data["exam"]["ExamType"];
      this.LinkSent=data["exam"]["LinkSent"];
      this.isGastro = this.GASTRO.includes(this.ExamType);
      this.changedTitle = data["exam"]["Changed"];
      this.newTitle = data["exam"]["ChangedExamTitle"];
      this.switchNT = this.changedTitle && this.newTitle== '1st & NT';
      this.addNT = this.changedTitle && this.newTitle == 'Early & NT';
      this.switchOvaries = data["exam"]["addOvaries"];
      this.setPurpose(data["exam"]["PurposeOfExam"]);
      this.isClosed=false;
      if(data["exam"]["printAllMeas"] !== null){
        this.printAllMeas.patchValue(data["exam"]["printAllMeas"])
      }else{
        this.printAllMeas.patchValue(true);
      }
      if(data["exam"]["AWLID"])
        this.AWLID = data["exam"]["AWLID"];
      this.Defaults=data['exam']['EDefault'];
      
      var closedd;
      if(data['exam']['ClosedDate'])
        closedd = new Date(data['exam']['ClosedDate']);
      if(data['exam']['OpenerName'])
        this.OpenerName = data['exam']['OpenerName'];
      else
        this.OpenerName = null;
      if(data['exam']['ClosedDate'])
        this.signed  = true;
      else
        this.signed = false;
      
      if(pid > 0 && ((!this.login.isCardio &&(viewonly || this.examClosed())) || 
        (this.login.userType != 'doctor' && this.login.userType != 'visitingdoc' && this.login.userType != 'tech') || 
        (this.login.isCardio  && data['exam']['ClosedDate']  && this.examClosed(closedd) )) ){
        this.isClosed=true;
        this.ps.Purpose.disable({emitEvent:false});
      }
      else{
        this.ps.Purpose.enable({emitEvent:false});
        //get worklist for local server
        if(this.login.localServer && (! this.login.lmp_in_mwl || this.ExamType == "Follicles" || this.ExamType == "Gynecology"))
          this.relayWorklist();
        this.openSocket();
      }
      if(preg)
        this.ps.setPreg(data["exam"]["PregCopied"] ? data["exam"] :data["pregnancy"],false,this.isClosed);
      this.PregCopied = data["exam"]["PregCopied"];
     
      this.examLoaded$.next();
    },
    (error)=>{
      console.log(error);
    });
  }

  goExamAdvancedSearch (exam, viewonly = true, supportCenter = false) {

    if(! exam){
      return;
    }

    this.examID = supportCenter ? exam.AutoIndex : exam.examID;
    this.pregId = exam.PregIndex;


    this.Fetus=1;
    this.router.navigate(['/exam']);
    let pid = exam.PID;
    this.ExamType = exam.ExamType;

    this.http.get("/api/exam", {params: {'ExamID': this.examID.toString(), 'PregIndex': this.pregId ? this.pregId.toString():'', 'PID': pid.toString(), 'viewonly': 'y'}} ).subscribe((data)=>{
      
      this.CreationTime = data["exam"]["CreationTime"];
      this.ExamType=data["exam"]["ExamType"];
      this.LinkSent=data["exam"]["LinkSent"];
      this.isGastro = this.GASTRO.includes(this.ExamType);
      this.changedTitle = data["exam"]["Changed"];
      this.newTitle = data["exam"]["ChangedExamTitle"];
      this.switchNT = this.changedTitle && this.newTitle== '1st & NT';
      this.addNT = this.changedTitle && this.newTitle == 'Early & NT';
      this.switchOvaries = data["exam"]["addOvaries"];
      this.setPurpose(data["exam"]["PurposeOfExam"]);
      this.isClosed=false;
      if(data["exam"]["printAllMeas"] !== null){
        this.printAllMeas.patchValue(data["exam"]["printAllMeas"])
      }else{
        this.printAllMeas.patchValue(true);
      }
      if(data["exam"]["AWLID"])
        this.AWLID = data["exam"]["AWLID"];
      this.Defaults=data['exam']['EDefault'];
      
      var closedd;
      if(data['exam']['ClosedDate'])
        closedd = new Date(data['exam']['ClosedDate']);
      if(data['exam']['OpenerName'])
        this.OpenerName = data['exam']['OpenerName'];
      else
        this.OpenerName = null;
      if(data['exam']['ClosedDate'])
        this.signed  = true;
      else
        this.signed = false;
      
      if(pid > 0 && ((!this.login.isCardio &&(viewonly || this.examClosed())) || 
        (this.login.userType != 'doctor' && this.login.userType != 'visitingdoc' && this.login.userType != 'tech') || 
        (this.login.isCardio  && data['exam']['ClosedDate']  && this.examClosed(closedd) )) ){
        this.isClosed=true;
        this.ps.Purpose.disable({emitEvent:false});
      }
      else{
        this.ps.Purpose.enable({emitEvent:false});
        //get worklist for local server
        if(this.login.localServer && (! this.login.lmp_in_mwl || this.ExamType == "Follicles" || this.ExamType == "Gynecology"))
          this.relayWorklist();
        this.openSocket();
      }
      if(exam)
        this.ps.setPreg(data["exam"]["PregCopied"] ? data["exam"] :data["pregnancy"],false,this.isClosed);
      this.PregCopied = data["exam"]["PregCopied"];
     
      this.examLoaded$.next();


    }),
    (error)=>{
      console.log(error);
    }

  }

  fillForm(form:UntypedFormGroup, table: string,savePregID = false,ignoreFetus = false,nosave = [],allowSaveWhenClosed = []){
    this.table = table;
    let params = {'table':table,ExamID: this.examID.toString()};
    if(!ignoreFetus && this.ExamType !== 'Pediatric Echo')
      params['fetus'] = this.Fetus.toString()
    this.IgnoreFetus = ignoreFetus;
    var s: Subscription;
    let oldSubs = this.formSubs;
    this.formSubs = [];
    setTimeout(()=>{ 
      while(s = oldSubs.pop())
        s.unsubscribe();
    },700);//wait for debounce to expire before unsubscribe
    let f = this.http.get("/api/get",{'params':params});
    f.subscribe((data)=>{
      if(! data.hasOwnProperty("empty")){
        for (let key in data) {
          if(form.get(key)){
            if(data[key]!== null)
              form.get(key).setValue(data[key],{emitEvent: false});//null ruins formcontrol
            else{
              if( form.get(key).value != '')
                form.get(key).setValue('',{emitEvent: false});
            }
             
          }
        }
      }
      else
        form.reset({emitEvent:false});
      for (let key in form.controls) {
        if(this.isClosed && ! allowSaveWhenClosed.includes(key)){
          form.get(key).disable();
        }
        else
          if(! nosave.includes(key))
            this.formSubs.push(form.controls[key].valueChanges.pipe(debounceTime(this.debounce), distinctUntilChanged()).subscribe(data=>{
              this.saveVals(key,form.get(key).value,savePregID, table);
            }));
      }
      
      this.formLoaded$.next();
    }, (error)=>{
      console.log(error);
    });
    return f;
  }

  fillUngrouped(formarray: UntypedFormControl[], table: string, use_queue = true){
    this.IgnoreFetus = false;
    this.table = table;
    var s: Subscription;
    let oldSubs = this.formSubs;
    this.formSubs = [];
    setTimeout(()=>{ 
      while(s = oldSubs.pop())
        s.unsubscribe();
    },700);
    let f = this.http.get("/api/get",{params:{'table':table,fetus: this.Fetus.toString(),ExamID: this.examID.toString()}});
    f.subscribe((data)=>{
      if(! data.hasOwnProperty("empty")){
        for (let key in data) {
          if(formarray.hasOwnProperty(key)){
            if(data[key]!== null)
              formarray[key].setValue(data[key],{emitEvent: false});
            else
                formarray[key].setValue('',{emitEvent: false});//empty string ruins formcontrol using dumb hack so things work in anatomy form
            
             
          }
        }
      }else{//empty - reset everything
        for(let key in formarray)
          formarray[key].reset('',{emitEvent:false});
      }
      for (let key in formarray) {
        if(this.isClosed){
          formarray[key].disable();
        }
        else{
          this.formSubs.push(formarray[key].valueChanges.pipe(debounceTime(this.debounce), distinctUntilChanged()).subscribe(data=>{
              this.saveVals(key,formarray[key].value,false,this.table,use_queue);
          }));
        }
      }
      this.formLoaded$.next();
      
    }, (error)=>{
      console.log(error);
    });
    return f;
  }

  setTable(tbl:string){
    this.table=tbl;
    this.IgnoreFetus = false;
  }

  saveVals(key: string, value: any,preg=false,table=this.table, use_queue=true){
    let p = this.patServ.getPat();
    let fetusNum;

    if(this.ExamType === 'Pediatric Echo'){
      fetusNum = null;
    } else {
      fetusNum = this.IgnoreFetus ? '1' : this.getFetus().toString()
    }

    let values = {
      'table': table,
      'indexvalue': this.examID,
      'PID': p.PID,
      'ID': p.ID,
      'CreationTime': this.CreationTime,
      'field': key,
      'value': value,
      'fetus': fetusNum,
    };
    if(preg){
      values["Preg"] = this.ps.pregID;
    }
    if(use_queue && this._saving){
      this._save_queue.push(values);
    }
    else{
      this.savePost(values);
    }
    
  }

  savePost(values){
    this._saving = true;
    this.http.post('/api/save',values).subscribe((data)=>{
      console.log(data);
      this._saving = false;
      this.checkQueue();
      //later on add observer for saves/errors
    }, (error)=> {// do this part later
      console.log(error);
      this._saving = false;
      this.checkQueue();
    });
  }

  checkQueue(){
    if(this._save_queue.length > 0 && ! this._saving){
      this.savePost(this._save_queue.shift());
    }
  }


  getUpDateConcent(){
    return this.http.get(`api/letters/concent/${this.concentForm['AutoIndex']}`)
  }
  

  getFindingsOptions(){
    return this.http.get(`api/Findings`)
  }

  
 async ExamFindings(){
  try{
    return await this.http.get(`api/Findings/exam/${this.examID}`).toPromise();
  }catch(error){
    console.error('Error ExamFindings:', error);
    return []
  }
  
  }

  addFinding(finding){
    const body={finding, examID: this.examID, PID: this.patServ.getPat().PID}
    return this.http.post('/api/Findings/exam',body)
  }

  removeFinding(finding){
    return this.http.delete('/api/Findings/exam/'+this.examID+'/'+finding.AutoIndex)
  }

  getConcentForExam(){
    let pid = this.patServ.getPat().PID
    return this.http.get(`api/letters/concentForExamID/${this.examID}/${pid}`)
  }
  examGA(ct = this.CreationTime):string{
    
    try{
      //return this.ps.getGA();
      if(ct == this.CreationTime)
        return this.ps.getGA();
      let examdays = Measure.WeekToDays(this.ps.getGA());
      let edate = new Date(ct);//the mssql comes out in a different format
      let gaexamdays = examdays - (new Date(this.CreationTime).getTime() - edate.getTime())/ (1000 * 24 * 60 * 60);
      return Measure.DaysToWeeks(gaexamdays);
    }
    catch{
      return "";
    }
  }

  israelDate(date) {
    const jsDate = moment(date).toDate();
    const day = jsDate.getDate();
    const month = jsDate.getMonth() + 1;
    const year = jsDate.getFullYear();
    const hours = jsDate.getHours();
    const minutes = jsDate.getMinutes();
    const seconds = jsDate.getSeconds();

    return `${year}-${this.padZero(month)}-${this.padZero(day)} ${this.padZero(
      hours
    )}:${this.padZero(minutes)}:${this.padZero(seconds)}`;
  }

  loginMissing(){
    if(! this.examID)
      this.router.navigate(['/']);
  }

  record(start:boolean){
    this.http.get('/api/record/'+this.examID+ '/' + (start?'start':'stop')).subscribe((data)=>{},(err)=>{console.warn(err)});
  }
  
  exitExam(){
    this.patServ.getPat().ID !== 'default' ? this.awaitPrintHistory = true : this.awaitPrintHistory = false;
    if (!this.isClosed && this.patServ.getPat().ID != 'default') {
      this.awaitPrintHistory = true;
      this.http.get('/api/examexit/'+this.examID+ '/'+ (this.AWLID ? this.AWLID: '0')).subscribe();
      let lang = this.login.printLang ? this.login.printLang : 'he-IL';
      if(this.ExamType != "Emergency Room")
      this.awaitPrintHistory = true;
        this.http.get('/api/printpdf/' + this.examID +'/' + this.patServ.getPat().PID + '/' + lang + '/y/n/simple/2/n').subscribe(()=>{
          this.awaitPrintHistory = false;
        },(err)=> {
          this.awaitPrintHistory = false;
          console.log(err);
        })
    }

    this.AWLID= null;

    if(this.GASTRO.includes(this.ExamType)){
      this.patServ.AWLStatus = "All";
      this.patServ.exitFromGastroExam.next(true);
    }
    this.closeSocket();

    if (this.isClosed) {
      this.awaitPrintHistory = false;
    }
  }

  savePrintALl(){
    this.saveVals('printAllMeas', this.printAllMeas.value, false, 'exams');
  }

  deleteExam(examid:number,awlid:number, table = null){
    this.http.delete('/api/exam/'+examid+'/'+(awlid ? awlid: 'none')+'/'+table).subscribe();
  }

  getLastSummary(){
    return this.http.get('/api/lastdiagnosis/'+ this.patServ.getPat().PID +'/' + this.examID +'/' + (this.ExamType == "Gynecology" ? 'y': 'n'));
  }

  getLastHistory(gyn=false){
    return this.http.get('/api/lastmedhistory/'+ this.patServ.getPat().PID +'/' + this.examID+'/'+ (gyn ? 'y': 'n'));
  }

  getLastHistoryFromClosedPreg(){
    return this.http.get('/api/lastmedhistoryclosed/'+ this.patServ.getPat().PID +'/' + this.examID);
  }

  sendLink(phone:string){
    return this.http.get("/api/sendlink",{params: {examid: this.examID.toString(), pnumber: phone, isLetter: this.ExamType === 'Consultation Letter' ? true : false}});
  }

  sendLinkConcent(phone:string, concentForm = null){    
    return this.http.get("/api/letters/sendlinkconcent",{params: {examID: this.concentForm['ExamID'], ConcentID:this.concentForm['AutoIndex'], pnumber: phone, isConsent: true}});
  }

  setDefaults(){
    return this.http.get('/api/applydefaults/'+ this.examID);
  }

  relayWorklist(){
    this.http.get('/api/worklist/'+ this.examID).subscribe((data)=>{
      if(data['worklist'])
        this.http.post('http://localhost:8088/worklist',{'worklist': data['worklist']}).subscribe((data)=>{},(err)=>{
          console.log(err);
        });
    },(error)=>{
      console.log(error);
    })
  }

  examSearch(fdate,tdate,examtype, uid){
    var fd;
    var td;
    try{
      fd = fdate._i.year+'-'+(fdate._i.month + 1)+'-'+fdate._i.date;
      td = tdate._i.year+'-'+(tdate._i.month + 1)+'-'+tdate._i.date;
    }catch{
      fd =  fdate.toISOString().slice(0, 19).split('T')[0];
      td = tdate.toISOString().slice(0, 19).split('T')[0];
    }
    let echosen = [];
    for(let e in examtype){
      if(examtype[e])
        echosen.push(e);
    }
    return this.http.get('/api/examsearch',{params: {from: fd, until: td,exam: JSON.stringify(echosen), uid:uid}});
  }

  reportSearch(format:string,filter:string,from,until,uid){
    const parsedFrom = this.parseDate(from)
    const parsedUntill = this.parseDate(until)
    return this.http.get('/api/stats/report',{params: {format,filter,from: parsedFrom, until: parsedUntill}});
  }

  parseDate (date) {
    const ms = moment(date).valueOf();
    const jsDate = new Date(ms);
    const day = jsDate.getDate();
    const month = jsDate.getMonth() + 1;
    const year = jsDate.getFullYear();
    return `${year}-${month}-${day}`;
  }

  parseConsentDate(date) {
    const jsDate = moment(date).toDate();
    const day = jsDate.getDate();
    const month = jsDate.getMonth() + 1;
    const year = jsDate.getFullYear();
    const hours = jsDate.getHours();
    const minutes = jsDate.getMinutes();
    const seconds = jsDate.getSeconds();

    return `${year}-${this.padZero(month)}-${this.padZero(day)} ${this.padZero(
      hours
    )}:${this.padZero(minutes)}:${this.padZero(seconds)}`;
  }

  padZero(number) {
    return number < 10 ? "0" + number : number;
  }

  parseDateDayMonthYear(date){
    const ms = moment(date).valueOf();
    const jsDate = new Date(ms);
    const day = jsDate.getDate();
    const month = jsDate.getMonth() + 1;
    const year = jsDate.getFullYear();
    return `${day}-${month}-${year}`;
  }
  

  getAllWeightAssByPre(pregID: number, examTime: any, NumFetuses:any){
    var temp:any = [];
    return this.http.get(`/api/newExams/getAllWeightAss/${pregID}/${examTime}/${NumFetuses}/${this.ExamType}`);
  }

  getAllWeightData(){
    return this.weightAssAll ? this.weightAssAll.slice() : [];
  }

  examGAByCreation(creationTime):string{
    try{
      //return this.ps.getGA();
      // if(creationTime == this.CreationTime)
      //   return this.ps.getGA();
      let examdays = Measure.WeekToDays(this.ps.getGA());
      let edate = new Date(creationTime);//the mssql comes out in a different format
      let gaexamdays = examdays - (new Date(this.CreationTime).getTime() - edate.getTime())/ (1000 * 24 * 60 * 60);
      return Measure.DaysToWeeks(gaexamdays);
    }
    catch{
      return "";
    }
  }

  getClosing(){
    return this.http.get('/api/cardio/closing/' + this.examID);
  }

  signExam(signer){
    return this.http.post('/api/cardio/close/'+ this.examID,{'signer': signer});
  }

  numOgFeatus(){
    // return this.ps.pregForm.value;
    return this.Fetus;
  }
  changeTitle(str:any,changedTitle?:boolean){
    this.http.post(`/api/newExams/changeTitle`, {patient: this.patServ.getPat(),examID: this.examID, oldTitle: this.ExamType, newExamTitle: str, Changed: this.changedTitle || changedTitle}).subscribe(
      (res:any)=>{
          this.newTitle = str;
          this.titleChange.next(this.changedTitle || changedTitle)
      }
    )
  }

  switchTONTExam(){
    if(this.ExamType == '1st') {
      this.switchNT = !this.switchNT;
      this.saveVals('Changed', this.switchNT, false, 'exams')
      this.saveVals('ChangedExamTitle', '1st & NT', false, 'exams')
      this.changedTitle = this.switchNT;
      this.newTitle = "1st & NT";
    }else{
      this.addNT = !this.addNT;
      this.saveVals('Changed', this.addNT, false, 'exams')
      this.saveVals('ChangedExamTitle', 'Early & NT', false, 'exams')
      this.changedTitle = this.addNT;
      this.newTitle = "Early & NT";
      this.NTAdd$.next();
    }
    
    // this.saveVals('printAllMeas', this.printAllMeas.value, false, 'exams');

  }
  addOvaries(){
    this.switchOvaries = !this.switchOvaries
    this.http.post("/api/newExams/addOvaries", {ExamID: this.examID, data:this.switchOvaries}).subscribe((data) => {
      console.log(data);
    })
  }

  getDisk(){
    return this.http.get('/api/disk/' + this.examID);
  }

  openSocket(){
    if(this.ws && this.ws.readyState == this.ws.OPEN)
      this.ws.close(1000, "Work complete");
    let path  =  (window.location.protocol == 'https:' ? 'wss://': 'ws://') + window.location.hostname + (window.location.port? ':' + window.location.port: '') + '/api/examsocket/' + this.examID;
      this.ws = new WebSocket(path);
      this.ws.onmessage = (ev)=>{
        this.mediaArrived$.next(ev.data);
        if(ev.data == 'html')
          this.getSRs();
        if(this.login.hasImaging && ev.data != "full"){
          this.audio.play();
        }
      }
  }

  closeSocket(){
    if(this.ws)
      this.ws.close(1000, "Work complete");
  }

  getAllCalForms(pergID){
    return this.http.get('/api/newExams/getAllCalForms/'+pergID+"/"+this.patServ.getPat().PID);
  }

  getConcentForms(pid:Number, AWLStatus = null){
    return this.http.get("/api/letters/getAllConcentForms/"+pid.toString())
  }

saveForm(save = false) {
    if (this.examID) this.concentForm.ExamID = this.examID;
    // if (this.pregId) this.concentForm.PregID = this.pregId;
    if (
      this.concentForm.PregID == "" &&
      this.pregnancies.length > 0 &&
      !this.pregnancies[0].ClosedDate
    )
      this.concentForm.PregID = this.pregnancies[0].AutoIndex;
    if (this.login.userType === "secretary") {
      this.concentForm.UID = this.consentDocUID;
    }

    if (save)
      this.http
        .post("/api/letters/saveConcentForm", {
          form: this.concentForm,
          pat: this.patServ.getPat(),
          pregID:
            (this.pregnancies.length  > 0) && !(this.concentForm.ExamType == "Gynecology") ? this.pregnancies[0].AutoIndex : null,
        })
        .subscribe((data) => {
          this.concentForm = data;
          this.login.concentCount=this.login.concentCount+1
          this.dataReceived$.next();
        });
  }

  async saveFormPrint(save = false) {
    if (this.examID) {
      this.concentForm.ExamID = this.examID;
    }

    if (this.concentForm.PregID === '' && this.pregnancies.length > 0 && !this.pregnancies[0].ClosedDate) {
      this.concentForm.PregID = this.pregnancies[0].AutoIndex;
    }
    if (this.login.userType === 'secretary') {
      this.concentForm.UID = this.consentDocUID;
    }
    
    if (save) {
      const data = await this.http.post("/api/letters/saveConcentForm", {
        form: this.concentForm,
        pat: this.patServ.getPat(),
        pregID:
        (this.pregnancies.length  > 0) && !(this.concentForm.ExamType == "Gynecology") ? this.pregnancies[0].AutoIndex : null,
      }).toPromise();
      this.consentSaved$.next();
      this.dataReceived$.next();
      this.concentForm = data;
      this.login.concentCount=this.login.concentCount+1
      return data;
    }
  }
  

  examClosed(examdate = null):boolean{
    let now = new Date();
    var closure = new Date();
    closure.setHours(3);
    closure.setMinutes(0);
    if(now.getHours() < 3){
        closure.setDate(closure.getDate() -1);
    }
    if(! examdate)
      examdate = new Date(this.CreationTime);
    return closure > examdate;
  }
  public showDefaultButton(){
    return this.login.ishospital && ! this.isClosed && this.login.defaultExams && this.login.defaultExams[this.ExamType] && this.Defaults == null && this.patServ.currentPatient.ID != 'default';
  }

  public showMissingLMPMsg(page: string) {
    const LMP = this.ps.pregForm.get('LMP').value;
    const LMP_US = this.ps.pregForm.get('LMP_US').value;
 
    const isMeasurementsPage = page === 'Measurements';
    const isObsDopplerPage = page === 'Obs Doppler';
    const isEitherLMPDataMissing = LMP || LMP_US;
 
    return !this.isClosed && (isMeasurementsPage || isObsDopplerPage) && !isEitherLMPDataMissing;
  }

  getAllPractitioners() {
    try {
      return this.http.get('/api/consent/practitioners');
    } catch (error) {
      console.error(error);
    }
  }

  async getPrevDocSign() {
    try {
      let body = {
        UID:
          this.login.userType === "doctor"
            ? this.login.UID
            : this.consentDocUID || this.concentForm.UID,
      };

      const response = await this.http.post("/api/consent/sign", body);
      return response;
    } catch (error) {
      console.error(error);
    }
  }

  
  fetchModality(){
    return new Promise((resolve, reject) => {
      this.http.get("/api/search/getModality",{params:{'table':'exams',ExamID: this.examID.toString()}}).subscribe((data)=>{
        this.modality=data['MODALITY'];
        resolve(this.modality); // resolve with this.modality value
      },
      (error)=>{
        console.log(error,'IN fetchModality');
        this.modality=null
        reject(error); // reject with error value
      });
    });
  }

  getAllTodaysExams(examType: string = "") {
    const patientID = String(this.patServ.getPat().ID);
    const examName = String(examType);
    try {
      return this.http.get(
        `/api/NewExams/todaysexams?patientID=${patientID}&examType=${examName}`
      );
    } catch (error) {
      console.error(error);
    }
  }

  continueExam(shouldContinue: boolean) {
    this.continueExamSource.next(shouldContinue);
  }
}